.whatsAppIcon {
  top: 27rem;
  right: 20px;
  z-index: 9999;
}

.enquiry {
  top: 32rem;
  right: 20px;
  z-index: 9999;
}